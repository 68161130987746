// import { RoughNotation } from "react-rough-notation";
import HeroImage from '../assets/KW-hero-img.png';
import Resume from '../assets/Katherine West Resume (2023).pdf'

const Hero = () => {
	const scrollToSection = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			const headerHeight = document.querySelector('.navbar').offsetHeight;
			const offset = headerHeight;
			const scrollTo = section.offsetTop - offset;
			window.scrollTo({
				top: scrollTo,
				behavior: 'smooth',
			});
		}
	};

	// 	return (
	// 		<div id='home'>
	// 			<section className='w-full'>
	// 				<div className='relative items-center w-full px-5 py-6 mx-auto md:px-14 lg:px-16 max-w-7xl lg:py-10'>
	// 					<div className='flex flex-col w-full mx-auto md:flex-row'>
	// 						<div className='relative inline-flex items-center mx-auto align-middle'>
	// 							<div className='text-center md:text-left'>
	// 								<h1 className='max-w-5xl text-3xl font-medium leading-none tracking-tighter text-black md:text-5xl lg:text-5xl lg:max-w-7xl'>
	// 									Hi, my name is <br />
	// 								</h1>
	// 								<h1 className='text-shadow-pop-br md:text-shadow-pop-br text-black text-4xl md:text-6xl font-bold mt-3 tracking-wider'>
	// 									Katherine West.
	// 								</h1>
	// 								<p className='max-w-xl mx-auto mt-8 text-base md:text-xl leading-relaxed md:leading-relaxed text-black'>
	// 									I'm a <strong>front-end developer</strong> driven by design
	// 									excellence and user-friendly interfaces. With proficiency in JavaScript,
	// 									React, and responsive design, I
	// 									<strong> bring engaging web experiences to life</strong>.
	// 								</p>
	// 								<div className='flex justify-center md:justify-start w-full max-w-2xl gap-4 mx-auto mt-10'>
	// 									<div className='sm:mt-0'>
	// 										<button className='primary-btn'>
	// 											View resume
	// 										</button>
	// 									</div>
	// 									<div className='sm:mt-0'>
	// 										<button onClick={() => scrollToSection('contact')} className='items-center block px-4 py-4 text-base font-medium text-center text-darkTeal transition duration-300 ease-in-out transform bg-lightTan lg:px-10 rounded-2xl border-2 border-teal hover:bg-lightTeal'>
	// 											Get in touch
	// 										</button>
	// 									</div>
	// 								</div>
	// 							</div>
	// 						</div>
	// 						<div className='w-full  md:ml-14 md:w-1/2 md:mt-0 md:items-center'>
	// 							<img
	// 								src={HeroImage}
	// 								alt='Illustration of a smiling girl working on a laptop'
	// 								className='mx-auto'
	// 							/>
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</section>
	// 		</div>
	// 	);
	// };

	// export default Hero;

	return (
		<div id='home'>
			<section className='w-full'>
				<div className='relative items-center w-full px-5 pt-10 pb-0 mx-auto md:px-14 lg:px-16 lg:pb-10 max-w-7xl'>
					<div className='flex flex-col w-full mx-auto lg:flex-row'>
						<div className='relative inline-flex items-center mx-auto align-middle'>
							<div className='text-center lg:text-left'>
								<h1 className='max-w-5xl text-3xl font-medium leading-none tracking-tighter text-black md:text-4xl'>
									Hi, my name is <br />
								</h1>
								<h1 className='text-shadow-pop-br md:text-shadow-pop-br text-black text-4xl md:text-5xl lg:text-6xl font-bold mt-3 tracking-wider'>
									Katherine West.
								</h1>
								<p className='max-w-xl mx-auto mt-8 text-lg md:text-xl leading-relaxed md:leading-relaxed text-black'>
									I'm a <strong>front-end developer</strong> driven by design
									excellence and user-friendly interfaces. With proficiency in
									JavaScript, React, and responsive design, I
									<strong> bring engaging web experiences to life</strong>.
								</p>
								<div className='flex justify-center lg:justify-start w-full max-w-2xl gap-4 mx-auto mt-10'>
									<div className='sm:mt-0'>
										<a className='primary-btn' href={Resume} target='_blank' rel='noopener noreferrer'>View resume</a>
									</div>
									<div className='sm:mt-0'>
										<button
											onClick={() => scrollToSection('contact')}
											className='items-center block px-4 py-4 text-base font-medium text-center text-darkTeal transition duration-300 ease-in-out transform bg-lightTan lg:px-10 rounded-2xl border-2 border-teal hover:bg-lightTeal'
										>
											Get in touch
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className='w-full mt-5 lg:ml-14 lg:w-1/2 lg:mt-0 items-center'>
							<img
								src={HeroImage}
								alt='Illustration of a smiling girl working on a laptop'
								className='mx-auto max-w-full'
							/>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Hero;
