import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: 'customColor.lightTan',
            }
        }
    },
    fonts: {
        body: "Rubik",
        heading: "Rubik",
    },
    colors: {
        customColor: {
            lightTan: '#F2EFEB',
            black: '#403D3D',
            teal: '#4B969B',
            darkTan: '#DED6CC',
            darkTeal: '#43858A',
            lightTeal: '#EFF6F6',
            mediumTan: '#E8E2DB',
        },
    },
    components: {
        Toast: {
            baseStyle: {
                success: {
                    bg: '#403D3D',
                    color: '#EFF6F6',
                },
            },
        },
    },
});

export default theme;
