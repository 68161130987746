import React, { useState } from 'react';
import Logo from '../assets/KW-logo-090823.png';
import { IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import CustomDrawer from './Drawer';

const Navbar = () => {
	const [openDrawer, setOpenDrawer] = useState(false);

	const handleOpenDrawer = () => {
		setOpenDrawer(!openDrawer);
	};

	const scrollToSection = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			const headerHeight = document.querySelector('.navbar').offsetHeight;
			const offset = headerHeight;
			const scrollTo = section.offsetTop - offset;
			window.scrollTo({
				top: scrollTo,
				behavior: 'smooth',
			});
			if (openDrawer === true) {
				handleOpenDrawer();
			};
		};
	};

	return (
		<div className='w-full bg-lightTan sticky top-0 z-[999]'>
			<div className='navbar'>
				<div className='flex shrink-0'>
					<img
						src={Logo}
						alt='Logo'
						className='w-[55px] cursor-pointer'
						onClick={() => scrollToSection('home')}
					/>
				</div>
				<ul className='hidden md:flex'>
					<li className='nav-list' onClick={() => scrollToSection('home')}>
						Home
					</li>
					<li className='nav-list' onClick={() => scrollToSection('skills')}>
						Skills
					</li>
					<li className='nav-list' onClick={() => scrollToSection('projects')}>
						Projects
					</li>
					<li className='nav-list' onClick={() => scrollToSection('contact')}>
						Contact
					</li>
				</ul>

				<div className='md:hidden'>
					<IconButton
						color='customColor.black'
						variant='ghost'
						aria-label='Navigation menu'
						icon={<HamburgerIcon boxSize={7} />}
						onClick={handleOpenDrawer}
						_hover={{ background: 'customColor.darkTan' }}
					/>
				</div>
				<CustomDrawer isOpen={openDrawer} onClose={handleOpenDrawer} scrollToSection={scrollToSection} />
			</div>
		</div>
	);
};

export default Navbar;
