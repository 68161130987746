import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useToast } from '@chakra-ui/react';

const Contact = () => {
	const form = useRef();
	const toast = useToast();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				'contact_service',
				'contact_form',
				form.current,
				'oJ5DxppfeSpff1P_h'
			)
			.then(
				(result) => {
					console.log(result.text);
					toast({
						position: 'bottom-right',
						description: "Sent! I'll get back to you ASAP.",
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
					form.current.reset();
				},
				(error) => {
					console.log(error.text);
					toast({
						position: 'bottom-right',
						description: 'An error occurred. Please try again!',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				}
			);
	};

	return (
		<section id='contact' className='w-full'>
			<div className='container px-5 mt-48 pb-40  md:pb-36 lg:pb-40 md:mt-52 mx-auto'>
				<div className='flex flex-col text-center w-full mb-8 text-black'>
					<h1 className='text-3xl md:text-4xl font-medium title-font mb-4'>
						Let's Work Together
					</h1>
					<p className='max-w-xl lg:w-2/3 mx-auto leading-relaxed text-lg'>
						Ready to team up on your next project? <br /> Feel free to
						<a
							href='mailto:katherinewest285@gmail.com'
							className='text-teal hover:text-darkTeal cursor-pointer duration-200 ease-in-out font-semibold tracking-wide'
						>
							{' '}
							email me{' '}
						</a>
						or fill out the form below.
					</p>
				</div>
				<form ref={form} className='lg:w-1/2 md:w-2/3 mx-auto text-black'>
					<div className='flex flex-wrap -m-2'>
						<div className='p-2 w-1/2'>
							<div className='relative'>
								<label for='name' className='leading-7 text-sm'>
									Name
								</label>
								<input
									type='text'
									id='name'
									name='user_name'
									className='w-full bg-mediumTan bg-opacity-50 rounded border border-darkTan focus:border-teal focus:bg-lightTeal focus:ring-2 focus:ring-lightTeal text-base outline-none text-black py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
								></input>
							</div>
						</div>
						<div className='p-2 w-1/2'>
							<div className='relative'>
								<label for='email' className='leading-7 text-sm'>
									Email
								</label>
								<input
									type='email'
									id='email'
									name='user_email'
									className='w-full bg-mediumTan bg-opacity-50 rounded border border-darkTan focus:border-teal focus:bg-lightTeal focus:ring-2 focus:ring-lightTeal text-base outline-none text-black py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
								></input>
							</div>
						</div>
						<div className='p-2 pb-0 w-full'>
							<div className='relative'>
								<label for='message' className='leading-7 text-sm'>
									Message
								</label>
								<textarea
									id='message'
									name='message'
									className='w-full bg-mediumTan bg-opacity-50 rounded border border-darkTan focus:border-teal focus:bg-lightTeal focus:ring-2 focus:ring-lightTeal text-base outline-none text-black py-1 px-3 leading-6 transition-colors duration-200 ease-in-out resize-none h-40'
								></textarea>
							</div>
						</div>
						<div className='px-8 pt-8 w-full flex justify-center'>
							<button onClick={sendEmail} className='primary-btn'>
								Send message
							</button>
						</div>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Contact;
