const Project = ({
	name,
	image,
	description,
	technologies,
	liveLink,
	githubLink,
}) => {
	return (
		<section className='text-lightTan overflow-hidden'>
			<div className='container px-5 py-10 mx-auto flex flex-col lg:flex-row items-center'>
				<div className='lg:w-[60%] w-full lg:mr-5 flex justify-center'>
					<a href={liveLink || githubLink} target='blank'>
						<img
							src={image}
							alt={name}
							className='h-auto rounded-2xl cursor-pointer duration-300 ease-in-out hover:scale-105 transition-transform'
						/>
					</a>
				</div>
				<div className='p-5 pt-8 lg:p-10 lg:w-[40%] flex flex-col items-start rounded-2xl'>
					<h2 className='sm:text-3xl text-2xl font-semibold tracking-widest mb-3 text-left'>
						{name}
					</h2>
					<div className='flex flex-wrap gap-2'>
						{technologies.map((technology) => (
							<span
								className='py-1 px-2 rounded bg-lightTeal text-darkTeal text-xs font-medium tracking-widest'
								key={technology}
							>
								{technology.toUpperCase()}
							</span>
						))}
					</div>
					<p className='leading-relaxed my-3 text-lg text-left'>
						{description}
					</p>
					<div className='flex items-center flex-wrap pb-4 mt-auto w-full text-lightTeal'>
						<span className='inline-flex items-center leading-none text-sm pr-3'>
							<a href={githubLink} target='blank'>
								<svg
									fill='currentColor'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									className='w-8 h-8 cursor-pointer duration-300 ease-in-out hover:scale-110  transition-transform'
									viewBox='0 0 24 24'
								>
									<path d='M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338 .726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524 .117-3.176 0 0 1.008-.322 3.301 1.23 .957-.266 1.983-.399 3.003-.404 1.02 .005 2.047 .138 3.006 .404 2.291-1.552 3.297-1.23 3.297-1.23 .653 1.653 .242 2.874 .118 3.176 .77 .84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921 .43 .372 .823 1.102 .823 2.222v3.293c0 .319.192 .694.801 .576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z' />
								</svg>
							</a>
						</span>
						{liveLink && (
							<a
								href={liveLink}
								target='blank'
								className='inline-flex items-center cursor-pointer duration-300 ease-in-out hover:bg-lightTeal hover:text-black border-lightTeal border-2 rounded-xl text-base px-2 py-1'
							>
								Visit site
								<svg
									className='w-4 h-4 ml-1'
									viewBox='0 0 24 24'
									stroke='currentColor'
									strokeWidth='2'
									fill='none'
									strokeLinecap='round'
									strokeLinejoin='round'
								>
									<path d='M5 12h14'></path>
									<path d='M12 5l7 7-7 7'></path>
								</svg>
							</a>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Project;
