import Project from "./Project";
import petPalsImage from '../assets/petpals-img.png';
import firstFriendImage from '../assets/first-friend-image1.png';
import onTrackImage from '../assets/ontrack-img.png';

const Projects = () => {
	
	const myProjects = [
		{
			name: 'PetPals',
			image: petPalsImage,
			description: 'PetPals is a full stack web app designed to connect pet owners within local communities. Users can create profiles for their pets, view other pet profiles, schedule pet playdates, and host or attend public meetups.',
			technologies: ['React', 'Flask', 'SQLAlchemy', 'Tailwind CSS', 'Bootstrap'],
			liveLink: 'https://phungmaiton.github.io/petpals/',
			githubLink: 'https://github.com/lisgarcia/petpals',
		},
		{
			name: 'First Friend',
			image: firstFriendImage,
			description: 'First Friend is a React app designed for learners of the Korean language to browse a collection of peer-approved study resources. Users can contribute to the collaborative learning environment by uploading materials like books, grammar articles, videos, and more.',
			technologies: ['React', 'Tailwind CSS', 'Material UI'],
			liveLink: 'https://phungmaiton.github.io/first-friend/',
			githubLink: 'https://github.com/lisgarcia/first-friend',
		},
		{
			name: 'OnTrack',
			image: onTrackImage,
			description: 'OnTrack is a CLI app designed to streamline the job application process. Users can organize and manage job applications, update the status and contact information for each position, delete outdated entries, and filter entries based on application status.',
			technologies: ['Flask', 'SQLAlchemy'],
			liveLink: '',
			githubLink: 'https://github.com/phungmaiton/ontrack',
		},
	]
	
	return (
		<div id='projects'>
			<section className='w-full bg-black text-lightTan'>

				<div className='relative'>
					<div className='custom-shape-divider-bottom-1695166188'>
						<svg
							data-name='Layer 1'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 1200 120'
							preserveAspectRatio='none'
						>
							<path
								d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
								class='shape-fill'
							></path>
						</svg>
					</div>
				</div>

				{/* Content section */}

				<div className='relative text-center items-center px-5 pt-12 pb-5 mx-auto md:px-14 lg:px-16 max-w-7xl'>
					<h1 className='text-3xl md:text-4xl mb-4 font-medium'>My Latest Projects</h1>
					{myProjects.map((project) => (
						<Project 
							key={project.name}
							name={project.name}
							image={project.image}
							description={project.description}
							technologies={project.technologies}
							liveLink={project.liveLink}
							githubLink={project.githubLink}
						/>
					))}
				</div>

				<div className='relative'>
				<div className="custom-shape-divider-top-1695252536 pt-5">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>
				</div>

			</section>
		</div>
	);
};

export default Projects;
