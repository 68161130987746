const Skills = () => {
	return (
		<section id='skills' className='w-full'>
			<div className='relative items-center w-full px-5 mx-auto max-w-7xl lg:px-16 pt-1 pb-48'>
				<div className='text-center mb-10'>
					<h1 className='text-3xl md:text-4xl font-medium text-black mt-4 mb-4'>
						Let's Talk Tech
					</h1>
					<p className='max-w-xl lg:w-2/3 mx-auto leading-relaxed text-lg'>
						While I specialize in the front end, I love learning new things and
						am always building my skill set in various facets of web
						development. Here's what I bring to the table:
					</p>
				</div>
				<div className='flex flex-wrap md:-m-4 -mx-4 -mt-4 md:space-y-0 space-y-3 justify-center'>
					<div className='p-4 md:w-1/3 w-full'>
						<div className='flex flex-col text-center items-center'>
							<div className='w-20 h-20 inline-flex items-center justify-center rounded-full bg-lightTeal text-teal mb-5 flex-shrink-0'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='w-10 h-10'
								>
									<path
										fill-rule='evenodd'
										d='M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm14.25 6a.75.75 0 01-.22.53l-2.25 2.25a.75.75 0 11-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 111.06-1.06l2.25 2.25c.141.14.22.331.22.53zm-10.28-.53a.75.75 0 000 1.06l2.25 2.25a.75.75 0 101.06-1.06L8.56 12l1.72-1.72a.75.75 0 10-1.06-1.06l-2.25 2.25z'
										clip-rule='evenodd'
									/>
								</svg>
							</div>
							<div className='flex-grow text-center'>
								<h2 className='text-black text-lg font-semibold mb-3'>
									Front-End Development
								</h2>
								<ul className='text-darkTeal leading-loose text-base mt-3'>
									<li>JavaScript</li>
									<li>React</li>
									<li>HTML</li>
									<li>CSS</li>
									<li>Tailwind CSS</li>
								</ul>
							</div>
						</div>
					</div>

					<div className='p-4 md:w-1/3 w-full'>
						<div className='flex flex-col text-center items-center'>
							<div className='w-20 h-20 inline-flex items-center justify-center rounded-full bg-lightTeal text-teal mb-5 flex-shrink-0'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='w-9 h-9'
								>
									<path d='M4.08 5.227A3 3 0 016.979 3H17.02a3 3 0 012.9 2.227l2.113 7.926A5.228 5.228 0 0018.75 12H5.25a5.228 5.228 0 00-3.284 1.153L4.08 5.227z' />
									<path
										fill-rule='evenodd'
										d='M5.25 13.5a3.75 3.75 0 100 7.5h13.5a3.75 3.75 0 100-7.5H5.25zm10.5 4.5a.75.75 0 100-1.5.75.75 0 000 1.5zm3.75-.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z'
										clip-rule='evenodd'
									/>
								</svg>
							</div>
							<div className='flex-grow text-center'>
								<h2 className='text-black text-lg font-semibold mb-3'>
									Back-End Development
								</h2>
								<ul className='text-darkTeal leading-loose text-base mt-3'>
									<li>Python</li>
									<li>Flask</li>
									<li>SQL</li>
									<li>SQLAlchemy</li>
									<li>Postman</li>
								</ul>
							</div>
						</div>
					</div>

					<div className='p-4 md:w-1/3 w-full'>
						<div className='flex flex-col text-center items-center'>
							<div className='w-20 h-20 inline-flex items-center justify-center rounded-full bg-lightTeal text-teal mb-5 flex-shrink-0'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='w-10 h-10'
								>
									<path
										fill-rule='evenodd'
										d='M2.25 5.25a3 3 0 013-3h13.5a3 3 0 013 3V15a3 3 0 01-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 01-.53 1.28h-9a.75.75 0 01-.53-1.28l.621-.622a2.25 2.25 0 00.659-1.59V18h-3a3 3 0 01-3-3V5.25zm1.5 0v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5z'
										clip-rule='evenodd'
									/>
								</svg>
							</div>
							<div className='flex-grow text-center'>
								<h2 className='text-black text-lg font-semibold mb-3'>
									Other Tools
								</h2>
								<ul className='text-darkTeal leading-loose text-base mt-3'>
									<li>Git</li>
									<li>GitHub</li>
									<li>Visual Studio Code</li>
									<li>Figma</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Skills;
