import React from 'react';
import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
} from '@chakra-ui/react';

function CustomDrawer({ isOpen, onClose, scrollToSection }) {
	return (
		<Drawer isOpen={isOpen} placement='right' size='xs' onClose={onClose}>
			<DrawerOverlay>
				<DrawerContent className='z-[9999]'>
					<DrawerCloseButton className='mt-2 hover:bg-darkTan' />
					<DrawerHeader
						color='customColor.black'
						borderColor='customColor.darkTan'
						letterSpacing='0.1em'
						className='border-b-4 font-bold'
					>
						Menu
					</DrawerHeader>
					<DrawerBody>
						<ul className='leading-[2.75rem] pt-2'>
							<li className='nav-list' onClick={() => scrollToSection('home')}>
								Home
							</li>
							<li
								className='nav-list'
								onClick={() => scrollToSection('skills')}
							>
								Skills
							</li>
							<li
								className='nav-list'
								onClick={() => scrollToSection('projects')}
							>
								Projects
							</li>
							<li
								className='nav-list'
								onClick={() => scrollToSection('contact')}
							>
								Contact
							</li>
						</ul>
					</DrawerBody>
				</DrawerContent>
			</DrawerOverlay>
		</Drawer>
	);
}

export default CustomDrawer;
