import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, CSSReset, ColorModeProvider } from '@chakra-ui/react';
import './index.css';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
          <ColorModeProvider options={{}}>
        <CSSReset />
    <React.StrictMode>
      <App />
    </React.StrictMode>
    </ColorModeProvider>
  </ChakraProvider>
);